<template>
  <div class="position-relative" id="contact">
    <img src="@/assets/layer_1.png" class="layer layer_1" alt="">
    <div class="container-fluid position-relative">
      <div class="container contact">
        <div class="row d-flex justify-content-between px-3">
          <div class="col-12 d-flex flex-column align-items-center my-5">
            <h1>{{ $t("contact.header") }}</h1>
            <p>
              {{ $t("contact.phone") }}: +372 5126736 <br>
              {{ $t("contact.email") }}: <a href="mailto:info@kromline.ee" class="mail">info@kromline.ee</a>
            </p>

            <div class="contact-form w-100 d-flex justify-content-center">
             <div class="col-xl-6 col-12 d-flex flex-column">
               <div class="d-flex flex-wrap gap-2 justify-content-between align-items-center mb-3">
                 <div class="col-12 col-md-5 d-flex flex-column">
                   <label for="name">{{ $t("contact.form.name") }}</label>
                   <input v-model="name" type="text" class="input-custom" id="name">
                 </div>
                 <div class="col-12 col-md-5 d-flex flex-column">
                   <label for="email">{{ $t("contact.form.email") }}</label>
                   <input v-model="email" type="text" class="input-custom" id="email">
                 </div>
               </div>
               <div class="col-12 flex-column d-flex">
                 <label for="intro">{{ $t("contact.form.intro") }}</label>
                 <textarea v-model="message" size="none" class="input-custom" id="intro"></textarea>
               </div>
               <div class="col-12 my-4 d-flex flex-column align-items-center">
                 <vue-recaptcha
                     class="my-3"
                     theme="light"
                     size="normal"
                     :tabindex="0"
                     @widgetId="recaptchaWidget = $event"
                     @verify="callbackVerify($event)"
                     @expired="callbackExpired()"
                     @fail="callbackFail()"
                 />
                 <button-custom v-if="recaptchaToken != null" @click="send()">
                    <span v-if="!loader && responseStatus == null">{{ $t("contact.form.send") }}</span>
                     <div v-if="loader" class="spinner-border text-danger m-auto" role="status">
                       <span class="visually-hidden">Loading...</span>
                     </div>
                    <span v-if="!loader && responseStatus != null">{{responseStatus}}</span>
                 </button-custom>
               </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent";
import {mapGetters} from "vuex";
import { ref } from "vue";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";
export default {
  name: "ContactComponent",
  components: {
    "button-custom": ButtonComponent,
    VueRecaptcha
  },
  computed: {...mapGetters(["getAxios"])},
  data(){
    return{
      name: "",
      email: "",
      message: "",
      recaptchaToken: null,
      loader: false,
      responseStatus: null
    }
  },
  setup: () => {
    // Reset Recaptcha
    const { resetRecaptcha } = useRecaptcha();
    const recaptchaWidget = ref(null);

    const callbackExpired = () => {
      console.log("expired!");
    };
    const callbackFail = () => {
      console.log("fail in google recaptcha");
    };
    // Reset Recaptcha action
    const actionReset = () => {
      resetRecaptcha(recaptchaWidget.value);
    };

    return {
      recaptchaWidget,
      callbackFail,
      actionReset,
      callbackExpired
    };
  },
  methods: {
    callbackVerify(response){
      this.recaptchaToken = response
    },
    send(){
      if(this.recaptchaToken){
        this.loader = true
        this.getAxios.post('/contact/send', {
          email: this.email,
          name: this.name,
          message: this.message,
          token: this.recaptchaToken
        }).then(response => {
          this.loader = false
          this.responseStatus = "Отправленно"
        }).catch(e => {
          this.loader = false
          this.responseStatus = "Произошла ошибка"
        })
      }
    }
  }
}
</script>

<style scoped>
.layer_1{
  transform: rotateZ(180deg);
  bottom: 106%;
}
.layer{
  overflow: hidden;
  width: 100%;
  position: absolute;
}
.contact h1{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #FFFFFF;
}
.contact div{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  color: #FFFFFF;
}
.mail{
  color: #FF003D;
  transition: 0.4s;
  text-decoration: none;
}
.mail:hover{
  color: #ff0059;
  transition: 0.4s;
}
.input-custom{
  border-radius: 12px;
  width: 100%;
  border: none;
  padding: 5px;
  transition: 0.3s;
  resize: none;
  box-sizing: border-box;
}
.input-custom:focus{
  outline: none;
}
.contact-form{
  width: 600px;
}
</style>