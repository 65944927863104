<template>
  <div class="">
    <div class="container navigation">
      <div class="row desktop-menu">
        <div class="col-4 col-md-3 d-flex justify-content-start align-items-center">
          <h1 @click="$router.push('/')">
            Kromline
          </h1>
        </div>
        <div class="nav-menu col-8 col-md-9 d-flex align-items-center justify-content-end">
          <ul class="d-md-flex d-none">
            <li>
              <a @click.prevent="navigate('about')">
                {{$t('navbar.about')}}
              </a>
            </li>
            <li>
              <a @click.prevent="navigate('services')">
                {{$t('navbar.services')}}
              </a>
            </li>
            <li>
              <a @click.prevent="navigate('gallery')">
                {{$t('navbar.gallery')}}
              </a>
            </li>
            <li>
              <a @click.prevent="navigate('contact')">
                {{$t('navbar.contact')}}
              </a>
            </li>
            <li class="switch">
              <LanguageSwitch/>
            </li>
          </ul>
          <span class="burger-menu d-flex d-md-none" @click="showMobileMenuAction()">
            <svg
                v-if="!showMobileMenu"
                xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
            <svg
                v-if="showMobileMenu"
                xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div :class="{'container navigation-mobile': true, 'd-none': !this.showMobileMenu}">
      <div class="row d-md-none desktop-menu desktop-menu-mobile">
        <div class="nav-menu col-12 d-flex align-items-center justify-content-around">
          <ul @click="linkHandler($event)">
            <li>
              <a @click.prevent="navigate('about')">
                {{$t('navbar.about')}}
              </a>
            </li>
            <li>
              <a @click.prevent="navigate('services')">
                {{$t('navbar.services')}}
              </a>
            </li>
            <li>
              <a @click.prevent="navigate('gallery')">
                {{$t('navbar.gallery')}}
              </a>
            </li>
            <li>
              <a @click.prevent="navigate('contact')">
                {{$t('navbar.contact')}}
              </a>
            </li>
            <li class="switch">
              <LanguageSwitch/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "@/components/locales/LanguageSwitch.vue";

export default {
  name: "Navbar",
  components: {
    "LanguageSwitch": LanguageSwitch
  },
  data(){
    return {
      showMobileMenu: false
    }
  },
  methods: {
    showMobileMenuAction(){
      console.log('ee')
      this.showMobileMenu = !this.showMobileMenu
    },
    linkHandler(e){
      console.log(e.target)
      if (!e.target.classList.contains("switch")) {
        this.showMobileMenu = false

        if (!e.target.classList.contains("link")){
          e.target.click()
        }
      }
    },
    navigate(anchor){
      if(this.$route.fullPath === "/"){
        let anchorBlock = document.querySelector(`#${anchor}`)
        if(anchorBlock !== null && anchorBlock !== undefined){
          window.scrollTo({
            top: anchor === 'services' ? anchorBlock.offsetTop + 200 : anchorBlock.offsetTop - 150,
            behavior: "smooth"
          })
        }
        return
      }

      this.$router.push("/")
      setTimeout(() => {
        this.navigate(anchor)
      }, 200)
    }
  }
}
</script>

<style scoped>
.burger-menu{
  color: white;
  font-weight: bold;
}
.nav-menu ul li a{
  text-decoration: none;
  text-transform: uppercase;

  /* Font part */
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: white;

  /* Animation part */
  text-decoration: none;
  background-image: linear-gradient(#FF003D,#FF003D);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .3s;
}

.nav-menu ul li a:hover{
  background-size: 100% 2px;
  color: white;
  cursor: pointer;
}
.nav-menu ul{
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 38px;
  display: flex;
}
.navigation h1{
  font-family: 'Jost';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  color: white;
  margin: 0;
  cursor: pointer;
}
.desktop-menu{
  height: 64px;
  padding: 0px 24px 0px 24px;
  margin-top: 27px;
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;

  animation-name: show-navigation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.navigation{
  z-index: 2;
  position: fixed;
  top: 92px;
  left: 50%;
  transform: translate(-50%, -107%);
}

.navigation-mobile{
  z-index: 2;
  position: fixed;
  top: 170px;
  left: 50%;
  transform: translate(-50%, -107%);
}
.switch{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .navigation{
    width: 90%;
  }
  .navigation-mobile{
    width: 90%;
  }
  .nav-menu ul li a{
    font-size: 16px;
  }
}
@media screen and (max-width: 450px) {
  .nav-menu ul{
    display: block;
  }
  .desktop-menu-mobile{
    height: fit-content!important;
  }
  .navigation-mobile{
    top: 230px;
  }
  .nav-menu ul li a{
    font-size: 14px;
  }
}
@keyframes show-navigation {
  from{
    transform: translateY(-100px);
    backdrop-filter: blur(5px);
  }

  to{
    transform: translateY(0px);
    backdrop-filter: blur(5px);
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
</style>
