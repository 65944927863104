<template>
  <div :class="{
    'image-block position-relative': true,
    'col-xl-3 col-md-5': useColumn
  }"
  @click="openProject(this.projectId)"
  >
    <img :src="'/static/' + image" alt="" class="image w-100">
    <img v-if="elipse" class="elipse position-absolute" src="@/assets/elipse1.png" alt="">
    <div class="layer position-absolute text-center" v-if="desc != null">
      <span>{{desc}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    elipse: {
      type: Boolean,
      default: false
    },
    image: {
      type: String
    },
    desc: {
      type: String,
      default: null
    },
    useColumn: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: Number,
      default: null
    }
  },
  name: "imageComponent",
  methods: {
    openProject(id){
      if(id != null) this.$router.push(`/project/${id}`)
    }
  }

}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .elipse{
    display: none;
  }
}
.image{
  border-radius: 12px;
}
.layer{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  top: 0;
  cursor: pointer;
  transition: 0.3s;
}
.image-block{
  border-radius: 12px;
  height: fit-content;
  overflow: hidden;
}
.layer:hover {
  opacity: 1;
  transition: 0.3s;
  background: linear-gradient(0deg, rgba(39, 39, 39, 0.86), rgba(39, 39, 39, 0.86));
}
.elipse{
  top: -150px;
  left: 122px;
  z-index: -1;
  width: 510px;
  height: 510px;
  animation: breath 2s alternate-reverse infinite ease-in ;
}
span{
  height: 150px;
  overflow: auto;
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>