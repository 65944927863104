<template>
  <div :class="{
    'col-12 images d-flex flex-wrap gap-4 mt-4 justify-content-md-between justify-content-center': true,
  }">
    <div v-if="loading" class="spinner-border text-danger m-auto" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p class="w-100 text-center" v-if="projects.length == 0 && loading == false">
      Контента нет
    </p>
    <image-component
        class="image"
        v-for="project in projects"
        :image="project.images[0]"
        :desc="project.title"
        :useColumn="true"
        :project-id="project.id"
    />
  </div>
</template>

<script>
import imageComponent from "@/components/ui/imageComponent";
export default {
  props: ["projects", "loading"],
  name: "projects",
  components: {
    "image-component": imageComponent,
  }
}
</script>

<style scoped>
</style>