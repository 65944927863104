<template>
  <router-view/>
</template>

<script>
import axios from "axios"
import {mapActions} from "vuex";
export default {
  app: "App",
  created(){
    const axiosInstance = axios.create({
      baseURL: process.env.NODE_ENV == "production" ? "https://kromline.ee/api/" : "http://localhost:3000/api/"
    })

    this.initAxios(axiosInstance)

    this.initLanguage()
  },
  methods: {
    ...mapActions(['initAxios', 'initLanguage'])
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
#app{
  position: relative;
}
body{
  background: #1D1D1D!important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@keyframes breath {
  from{
    opacity: 1;
    transform: translateY(0px);
  }
  to{
    opacity: 1;
    transform: translateY(10px);
  }
}

@keyframes breath-reverse {
  from{
    opacity: 1;
    transform: translateY(10px);
  }
  to{
    opacity: 1;
    transform: translateY(0px);
  }
}


</style>
