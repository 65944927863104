import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue3 from 'bootstrap-vue-3'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import {createI18n} from "vue-i18n"
import VueRecaptcha from "vue3-recaptcha-v2";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const messages = {
        en: require("./locales/en.json"),
        ru: require("./locales/ru.json"),
        ee: require("./locales/ee.json"),
}

const i18n = createI18n({
        locale: 'ee', // set locale
        fallbackLocale: 'ee', // set fallback locale
        messages, // set locale messages
})

createApp(App)
    .use(VueRecaptcha, {
        siteKey: "6Lf9JyQkAAAAAANC35ilckajqH04kmfMut6RCj8s",
        hideBadge: false, // Hide badge element (v3 & v2 via size=invisible)
        language: "ru",   // Recaptcha language (v2)
        mode: 'base',       // Mode: 'base', 'enterprise'
        version: 2,    // Version
    })
    .use(BootstrapVue3)
    .use(store)
    .use(router)
    .use(BootstrapIconsPlugin)
    .use(i18n)
    .mount('#app')

export default i18n