<template>
  <div class="container gallery" id="gallery">
    <div class="row d-flex justify-content-between px-3">
      <div class="col-12 d-flex flex-column align-items-center" v-if="categories.length != 0">
        <h1>{{ $t("gallery.header") }}</h1>
        <p>{{ $t("gallery.desc") }}</p>
        <div class="menu d-flex gap-3 gap-xl-4 flex-wrap mt-2 mb-3">
          <div
              :class="{'menu-item d-flex justify-content-center': true, 'active': category.selected}"
              v-for="category in categories"
              @click="selectCategory(category)"
          >{{category.title}}</div>
        </div>
        <projects
          :projects="projects"
          v-model:loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import projectsComponent from "@/components/ui/projectsComponent";
export default {
  name: "GalleryComponent",
  components: {
    "projects": projectsComponent
  },
  computed: {...mapGetters(['getAxios', 'getActiveLanguage'])},
  data(){
    return {
      categories: [],
      projects: [],
      loading: true
    }
  },
  mounted(){
    this.getData()
  },
  methods: {
    defineProjects(){
      this.loading = true
      let category = this.defineCategorySelected()
      if(category){
        if (category.id == "all"){
          let projects = []
          this.categories.forEach(category => {
            if(category.id != "all"){
              category.projects.forEach(project => {
                projects.unshift(project)
              })
            }
          })
          this.loading = false
          return projects
        }
        this.loading = false
        return category.projects
      }
      this.loading = false
      return []
    },
    defineCategorySelected(){
      for(let category of this.categories){
        if(category.selected) return category
      }
    },
    selectCategory(category){
      (this.defineCategorySelected()).selected = false
      category.selected = true
      this.projects = this.defineProjects()
    },
    getData(){
      this.getAxios("/services?lang=" + this.getActiveLanguage.id).then(response => {
        let services = response.data
        services.forEach(service => {
          service.selected = false
          service.projects.forEach(project => {
            project.images = JSON.parse(project.images)
          })
        })

        this.categories = services
        this.categories.unshift(
            {
              title: this.$t("gallery.all"),
              id: "all",
              selected: true
            },
        )

        setTimeout(() => {
          this.projects = this.defineProjects()
        }, 500)
      })
    }
  }
}
</script>

<style scoped>
.gallery{
  margin-top: 100px;
  margin-bottom: 350px;
}
.gallery h1{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #FFFFFF;
}
.gallery div{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  color: #FFFFFF;
}
.menu-item{
  cursor: pointer;
  border: 4px solid rgba(255, 0, 61, 0.54);
  border-radius: 30px;
  padding: 5px 30px 5px 30px;
  transition: 0.3s;
}
.menu-item:hover{
  transition: 0.3s;
  background: #D30033;
  border: 4px solid rgba(255, 0, 61, 0.54);
  box-shadow: 0px 0px 19px #FF003D;
  border-radius: 30px;
}
.active{
  transition: 0.3s;
  background: #D30033;
  border: 4px solid rgba(255, 0, 61, 0.54);
  box-shadow: 0px 0px 19px #FF003D;
  border-radius: 30px;
}
.image{
  width: 400px;
}
</style>