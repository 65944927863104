<template>
  <div id="services">
    <div class="position-relative">
      <img src="@/assets/layer_1.png" class="layer layer_1" alt="">
      <div class="container-fluid service-full position-relative">
        <div class="container about">
          <div class="row d-flex justify-content-between px-3 service">
            <h1>{{$t("service.header")}}</h1>
            <p>{{$t("service.desc")}}</p>

            <div class="position-relative">
              <Carousel ref="slides" :settings="settings" :breakpoints="breakpoints">
                <Slide v-for="slide in slides" :key="slide">
                  <div class="carousel__item">
                    <image-component
                        :desc="slide.title"
                        :image="slide.image"
                        @click="$router.push('/service/' + slide.id)"
                    />
                    <h2>{{slide.title}}</h2>
                  </div>
                </Slide>
              </Carousel>
              <div class="navigation">
         <span
             :class="{'navigation-item': true, 'active': slide_active === slide - 1}"
             v-for="slide in slides.length"
             @click="changeIndex(slide - 1)"
         ></span>
              </div>
              <img class="elipse elipse1" src="@/assets/elipse1.png" alt="">
              <img class="elipse elipse2" src="@/assets/elipse2.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <img src="@/assets/layer_1.png" class="layer layer_2" alt="">
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import ImageComponent from "@/components/ui/imageComponent";

import 'vue3-carousel/dist/carousel.css'
import {mapGetters} from "vuex";

export default {
  name: "ServiceComponent",
  components: {
    Carousel,
    Slide,
    "image-component": ImageComponent
  },
  computed: {...mapGetters(['getAxios','getActiveLanguage'])},
  mounted() {
    this.slide_active = 0
    this.getAxios("/services?lang=" + this.getActiveLanguage.id).then(response => {
      //Services
      this.slides = response.data
    })
  },
  data: () => ({
    //slides data
    slideRef: null,
    slide_active: null,
    divider: 3,
    slides: [],
    // carousel settings
    settings: {
      itemsToShow: 2.5,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      100: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      1000: {
        itemsToShow: 2,
        snapAlign: 'center',
      },
      1360: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    },
  }),
  methods: {
    changeIndex(index) {
      this.slide_active = index
      this.$refs.slides.slideTo(index)
    },
  },
}
</script>

<style scoped>
.service-full{
  overflow: hidden;
  padding: 50px 0px 50px 0px;
}
.layer_1{
  transform: rotateZ(180deg);
  margin-bottom: 150px;
}
.layer_2{
  margin-top: 150px;
}
.layer{
  width: 100%;
}
.navigation{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 50px;
}
.navigation-item{
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 947px;
  top: 985px;

  border: 4px solid #D30033;
  filter: drop-shadow(0px 0px 15px #FF003D);
  border-radius: 50%;
  transition: 0.4s;
}
.navigation-item:hover{
  filter: drop-shadow(0px 0px 10px #FF003D);
  transition: 0.4s;
  background: #D30033;
}
.active{
  background: #D30033;
}
.row{
  color: white;
}
.service h1{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #FFFFFF;
}
.service h2{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin-top: 18px;
}
.service p{
  margin-bottom: 105px;
}
.service {
  text-align: center;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  color: #FFFFFF;
}

.elipse{
  position: absolute;
  width: 500px;
  animation: 5s breath-with-scale-771b5c20 alternate-reverse ease-in-out infinite;
  z-index: -1;
  top: -100px;
}
.elipse1{
  left: 80%;
}
.elipse2{
  right: 80%;
}

.carousel__item {
  min-height: 400px;
  width: 400px;
  border-radius: 12px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel__item img{
  width: 100%;
}

@keyframes breath-with-scale {
  from{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  to{
    opacity: 1;
    transform: translateY(10px) scale(0.8);
  }
}
</style>