<template>
  <a class="button" :href="url">
    <slot/>
  </a>
</template>

<script>
export default {
  props: ['url'],
  name: "Button"
}
</script>

<style scoped>

.button {
  background: #D30033;
  border: 4px solid rgba(255, 0, 61, 0.54);
  box-shadow: 0px 0px 19px #FF003D;
  border-radius: 30px;
  padding: 9px 68px 9px 68px;
  cursor: pointer;

  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 46px;

  color: #FFFFFF;
  text-decoration: none;
  transition: 0.3s;

  text-align: center;
}
.button:hover {
  box-shadow: 0px 0px 25px #FF003D;
  transition: 0.3s;
}
</style>