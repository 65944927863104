<template>
  <div>
    <a class="switch" @click="open">
      {{ getActiveLanguage.icon }}
    </a>
    <div :class="{'languages': true,'show': showSwitchBlockAnimation, 'hide': !showSwitchBlockAnimation}">
      <a class="link" :langID="lang.id" v-for="lang in getAvailableLanguages" @click="sLanguage(lang.id)">
        {{lang.icon}}
      </a>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "LanguageSwich",
  computed: {...mapGetters(['getActiveLanguage', 'getAvailableLanguages'])},
  data() {
    return {
      showSwitchBlockAnimation: false
    }
  },
  methods: {
    ...mapActions(['switchLanguage']),
    open() {
      this.showSwitchBlockAnimation = !this.showSwitchBlockAnimation
    },
    sLanguage(langID) {
      this.switchLanguage(langID)
      this.showSwitchBlockAnimation = false
      document.location = '/'
    }
  }
}
</script>

<style scoped>
.switch{
  cursor: pointer;
  text-decoration: none;
}
.languages{
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: 0.3s;
}
.show {
  animation: show 0.3s forwards;
}
.hide {
  animation: hide 0.3s forwards;
}

.languages a {
  cursor: pointer;
  color: white;
  text-decoration: none;
}
@keyframes show {
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hide {
  from{
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>