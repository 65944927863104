<template>
  <navigation/>
  <div class="loading" v-show="loading">
    <div class="spinner-border text-danger m-auto" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="content" v-show="!loading">
    <intro
        class="show"
        v-if="service != null"
        :service="service"
    />
    <div class="container my-5 show-2">
      <div class="row">
        <div class="col-12">
          <h2>Проекты</h2>
          <projects
              v-if="!loading"
              v-model:loading="loading"
              :projects="service.projects"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/elements/Navbar";
import IntroductionComponent from "@/components/service/IntroductionComponent";
import {mapGetters} from "vuex";
import projectsComponent from "@/components/ui/projectsComponent";
export default {
  name: "ServicePage",
  components: {
    "navigation": Navbar,
    "intro": IntroductionComponent,
    "projects": projectsComponent
  },
  computed: {...mapGetters(["getAxios"])},
  data(){
    return{
      id: this.$route.params.id,
      service: null,
      loading: false
    }
  },
  async created() {
    if(this.id === null || this.id === undefined){
      this.$router.push("/")
    }

    this.loading = true
    try {
      this.service = (await this.getAxios.get("/services/" + this.id)).data
      this.service.projects.forEach(project => {
        project.images = JSON.parse(project.images)
      })
      setTimeout(()=>{
        this.loading = false
      }, 1000)
    } catch (e){
      this.loading = false
      console.log("cannot get service...")
    }
  }
}
</script>

<style scoped>
.loading{
  width: 100%;
  height: 100vh;
  display: flex;
}
.content{
  color: white;
}
.show{
  animation: show 2s forwards ease-in-out 0s;
}
.show-2{
  animation: show 1s forwards ease-in-out 0s;
}
@keyframes show {
  from{
    opacity: 0;
    transform: translateY(-50px);
    backdrop-filter: blur(5px);
  }

  to{
    opacity: 1;
    transform: translateY(0px);
    backdrop-filter: blur(5px);
  }
}
</style>