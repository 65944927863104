<template>
  <div class="position-relative">
    <div class="container-fluid header-full position-relative">
      <div class="container header">
        <div class="row w-100">
          <div class="col-12">
            <div class="header-content row">
              <div class="col-12 col-xl-6 d-flex justify-content-center align-items-start flex-column">
                <h1>Kromline</h1>
                <p>
                  {{ $t("header.desc_first")}}<br>
                  {{ $t("header.desc_second")}}
                </p>
                <button-custom class="button" :url="'#about'">{{ $t("header.additional")}}</button-custom>
              </div>
              <div class="mt-5 mt-md-0 col-xl-6 d-none d-md-flex justify-content-center align-items-center video-content">
                <div class="video">
                  <img :class="{'vector1': true, 'show-vector': vector1.show, 'vector_breath': vector1.breath}" src="@/assets/vector1.png" alt="">
                  <video autoplay muted loop>
                    <source src="@/assets/preview.mp4" type="video/mp4">
                  </video>
                  <img :class="{'vector2': true, 'show-vector': vector2.show, 'vector_breath_revert': vector2.breath}" src="@/assets/vector2.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/layer_1.png" class="layer_1" alt="">
  </div>

</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent";
export default {
  name: "headerComponent",
  components: {
    "button-custom": ButtonComponent
  },
  data() {
    return {
      vector1:{
        show: true,
        breath: false
      },
      vector2:{
        show: true,
        breath: false
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.vector1.show = false
      this.vector1.breath = true

      this.vector2.show = false
      this.vector2.breath = true
    }, 3000)
  }
}
</script>

<style scoped>
@media screen and (max-width: 1200px) {
  .header-content {
    margin-top: 150px!important;
  }
  .video-content{
    margin-top: 157px!important;
  }
  h1, p{
    text-align: center;
  }
  .button{
    margin: auto;
  }
}
.header-content{
  margin-top: 200px;
  margin-bottom: 150px;
}
.header-full{
  overflow: hidden;
}
.layer_1{
  width: 100%;
}
.video{
  position: relative;
}
.video video{
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 26px 13px rgba(36, 36, 36, 0.4);
  border-radius: 30px;
  width: 523px;
  opacity: 0;
  animation: show-video 2s forwards ease-in-out 0.5s;
}
.show-vector{
  animation: vector-show 2s forwards ease-in-out 1s;
}
.vector_breath{
  animation: breath 2s infinite alternate ease-in-out;
}
.vector_breath_revert{
  animation: breath-reverse 2s infinite alternate-reverse ease-in-out;
}
.vector2{
  position: absolute;
  width: 400px;
  bottom: 0;
  right: -28%;
  top: -51%;
  opacity: 0;
}
.vector1{
  position: absolute;
  width: 400px;
  bottom: 0;
  left: -28%;
  top: 34%;
  opacity: 0;
}
.header{
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 35px;
}
h1{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  margin-bottom: 10px;
  width: 100%;
}
p{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 46px;
  width: 100%;
  margin-bottom: 55px;
}

@keyframes vector-show {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes show-video {
  from{
    opacity: 0;
    transform: translateY(-50px);
    backdrop-filter: blur(5px);
  }

  to{
    opacity: 1;
    transform: translateY(0px);
    backdrop-filter: blur(5px);
  }
}
</style>