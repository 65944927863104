<template>
  <navigation/>
  <header-component/>
  <about-component/>
  <service-component/>
  <gallery-component/>
  <contact-component/>
  <footer-component/>
</template>

<script>
import HeaderComponent from "@/components/elements/HeaderComponent";
import AboutComponent from "@/components/elements/AboutComponent";
import ServiceComponent from "@/components/elements/ServiceComponent";
import GalleryComponent from "@/components/elements/GalleryComponent";
import ContactComponent from "@/components/elements/ContactComponent";
import FooterComponent from "@/components/elements/FooterComponent";
import Navigation from "@/components/elements/Navbar"
export default {
  name: 'HomeView',
  components: {
    'header-component': HeaderComponent,
    'about-component': AboutComponent,
    'service-component': ServiceComponent,
    'gallery-component': GalleryComponent,
    'contact-component': ContactComponent,
    'footer-component': FooterComponent,
    "navigation": Navigation
  }
}
</script>

<style scoped="true">
h1{
  color: white;
}
</style>
