<template>
  <div class="container">
    <div class="row px-sm-3 px-0">
      <div class="col-12 mb-3 justify-content-between align-items-center d-flex navigation">
        <svg @click="$router.back()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-square-fill back" viewBox="0 0 16 16">
          <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
        </svg>

        <p class="m-0">{{getDate(service.createdAt)}}</p>
      </div>
      <div class="col-12 col-md-4 ">
        <image-component
          :image="service.image"
        />
      </div>
      <div class="col-12 mt-2 mt-md-0 col-md-8 description d-flex flex-column">
        <h1>{{ service.title }}</h1>
        <p>{{ service.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from "@/components/ui/imageComponent";
export default {
  props: ["service"],
  name: "IntroductionComponent",
  components: {
    "image-component": ImageComponent,
  },
  methods: {
    getDate(dateString){
      let date = new Date(dateString)
      return date.toLocaleDateString("ru-RU")
    }
  }
}
</script>

<style scoped>
.container{
  margin-top: 150px;
}
.navigation{
  color: #a8a8a8;
}
.back{
  color: #a8a8a8;
  cursor: pointer;
  transition: 0.3s;
}
.back:hover{
  color: #FF003D;
  transition: 0.3s;
}
</style>