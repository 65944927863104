import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServicePage from "@/views/ServicePage";
import ProjectPage from "@/views/ProjectPage";
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Skills - MyApp' }
  },
  {
    path: '/service/:id',
    name: 'service',
    component: ServicePage,
    meta: { title: 'Service' }
  },
  {
    path: '/project/:id',
    name: 'project',
    component: ProjectPage,
    meta: { title: 'Project' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
