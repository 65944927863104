import { createStore } from 'vuex'
import i18n from "../main"
export default createStore({
  state: {
    root: {
      axios: null
    },
    languages: {
      current: "ee",
      list: {
        ee: {
          id: "ee",
          icon: "🇪🇪"
        },
        en: {
          id: "en",
          icon: "🇺🇸"
        },
        ru: {
          id: "ru",
          icon: "🇷🇺"
        }
      }
    }
  },
  getters: {
    getAxios(state){
      return state.root.axios
    },
    getActiveLanguage(state) {
      return state.languages.list[state.languages.current]
    },
    getAvailableLanguages(state){
      let available = []
      Object.keys(state.languages.list).forEach( lang => {
        if (lang !== state.languages.current) {
          available.push(state.languages.list[lang])
        }
      })
      return available
    }
  },
  mutations: {
    initAxiosMutation(state, axios){
      state.root.axios = axios
    },
    switchLanguageMutation(state, langID) {
      setTimeout(() => {
        i18n.global.locale = langID
      }, 100)
      state.languages.current = langID

      sessionStorage.setItem("lang", state.languages.current)
    }
  },
  actions: {
    initAxios(context, axios){
      context.commit("initAxiosMutation", axios)
    },
    initLanguage(context){
      let langID = sessionStorage.getItem("lang")
      if (langID !== undefined && langID !== null && langID !== "") {
        context.commit("switchLanguageMutation", langID)
      }
    },
    switchLanguage(context, langID) {
      context.commit("switchLanguageMutation", langID)
    }
  },
  modules: {
  }
})
