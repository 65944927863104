<template>
  <appNav/>
  <div class="loading" v-show="loading">
    <div class="spinner-border text-danger m-auto" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="content show" v-show="!loading">
    <div class="container" v-if="project != null">
      <div class="row px-sm-3 px-0">
        <div class="col-12 mb-3 justify-content-between align-items-center d-flex navigation" v-if="project != null && project != undefined">
          <svg @click="$router.back()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-square-fill back" viewBox="0 0 16 16">
            <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
          </svg>

          <p class="m-0">{{getDate(project.createdAt)}}</p>
        </div>
        <div class="col-12 col-md-4 show" v-if="renderSlider">
          <Carousel ref="slides" :settings="settings">
            <Slide v-for="image in project.images" :key="image">
              <div class="carousel__item">
                <image-component
                    :image="image"
                />
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>

        </div>
        <div class="col-12 mt-2 mt-md-0 col-md-8 description d-flex flex-column">
          <h1>{{ project.title }}</h1>
          <p>{{ project.description }}</p>
          <span class="my-3">
            <b>Категории: </b>
            <a @click.prevent="this.$router.push('/service/' + service.id)" class="service_link" v-for="service in project.services">
              {{service.title}} <span v-if="project.services.length > 1">|</span>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/elements/Navbar";
import {mapGetters} from "vuex";
import ImageComponent from "@/components/ui/imageComponent";
import { Carousel, Navigation, Slide } from 'vue3-carousel'

export default {
  name: "ServicePage",
  components: {
    Carousel,
    Slide,
    Navigation,
    "appNav": Navbar,
    "image-component": ImageComponent,
  },
  computed: {...mapGetters(["getAxios"])},
  data(){
    return{
      id: this.$route.params.id,
      project: null,
      loading: false,
      renderSlider: false,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        autoplay: 2000,
        transition: 800,
        pauseAutoplayOnHover: true
      },
    }
  },
  async created() {

    setTimeout(() => {
      this.renderSlider = true
    }, 2500)
    if(this.id === null || this.id === undefined){
      this.$router.push("/")
    }

    this.loading = true
    try {
      this.project = (await this.getAxios.get("/projects/one/" + this.id)).data
      this.project.images = JSON.parse(this.project.images)
      setTimeout(()=>{
        this.loading = false
      }, 1000)
    } catch (e){
      this.loading = false
      console.log("cannot get service...")
    }
  },
  methods: {
    getDate(dateString){
      let date = new Date(dateString)
      return date.toLocaleDateString("ru-RU")
    }
  }
}
</script>

<style scoped>
.loading{
  width: 100%;
  height: 100vh;
  display: flex;
}
.service_link{
  cursor: pointer;
  color: #FF003D;
  transition: 0.4s;
  text-decoration: none;
}
.service_link:hover{
  color: #ff0059;
  transition: 0.4s;
}

.content{
  color: white;
}
.container{
  margin-top: 150px;
}
.show{
  animation: show 0.5s forwards ease-in-out 0s;
}
@keyframes show {
  from{
    opacity: 0;
    transform: translateY(-50px);
    backdrop-filter: blur(5px);
  }

  to{
    opacity: 1;
    transform: translateY(0px);
    backdrop-filter: blur(5px);
  }
}
</style>