<template>
  <div class="container about" id="about">
    <div class="row d-flex justify-content-between px-3">
      <div class="col-12 col-xl-6 mb-xl-0 mb-5">
        <h1>{{ $t("about.header") }}</h1>
        <div>
          <p>
            {{ $t("about.desc_first") }} <br><br>
            {{ $t("about.desc_second") }}
          </p>
          <ul>
            <li>{{ $t("about.list.1") }}</li>
            <li>{{ $t("about.list.2") }}</li>
            <li>{{ $t("about.list.3") }}</li>
            <li>{{ $t("about.list.4") }}</li>
            <li>{{ $t("about.list.5") }}</li>
          </ul>
          <p>
            {{ $t("about.desc_third") }}
          </p>
        </div>
        <br>
        <button-custom url="#service">{{ $t("about.additional") }}</button-custom>
      </div>
      <div class="col-12 col-xl-5 mt-xl-0 mt-5 m-auto d-flex justify-content-center align-items-start portfolio-image">
        <image-component
            @click.prevent="$router.push('/project/' + lastProject.id)"
            v-if="lastProject"
          :elipse="true"
          :image="lastProject.images[0]"
          :desc="lastProject.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent";
import ImageComponent from "@/components/ui/imageComponent";
import {mapGetters} from "vuex"
export default {
  name: "AboutComponent",
  components: {
    "button-custom": ButtonComponent,
    "image-component": ImageComponent
  },
  computed: {...mapGetters(['getAxios', "getActiveLanguage"])},
  data(){
    return {
      lastProject: null,
    }
  },
  async mounted(){
    this.lastProject = ((await this.getAxios.get("/projects/latest?lang=" + this.getActiveLanguage.id)).data)[0]
    this.lastProject.images = JSON.parse(this.lastProject.images)
  },
}
</script>

<style scoped>
.about{
  margin-top: 100px;
}
.about h1{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #FFFFFF;
}
.about div{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  color: #FFFFFF;
}
.portfolio-image{
  width: 537px;
  height: 403px;
  left: 1136px;
  top: 416px;
  border-radius: 12px;
  margin-bottom: 450px!important;
}

</style>